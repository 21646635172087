import { createApp, defineAsyncComponent } from 'vue';
import store from 'shared/store';
import GeneralLinkBlock from "multisite/components/cmsContentBlocks/generalLink.vue";
import { initSwiperMount } from "shared/vanilla/initSwiperMount";
import "./heroHeaderBlock.scss";

const heroHeaderBlockContainers = document.querySelectorAll(
	"[data-feature='heroHeaderBlock']"
);

// Mount Vue components
heroHeaderBlockContainers.forEach((heroHeaderBlockContainer) => {
	const elements: NodeList = heroHeaderBlockContainer.querySelectorAll(
		'[data-hero-header-content-block]'
	);
	if (elements.length) {
		elements.forEach((element) => {
			const htmlElement: HTMLElement = element as HTMLElement;

			const app = createApp({
				name: htmlElement?.dataset?.blockName,
			});

			app.component(
				'hero-header-search-placeholder-wrapper',
				defineAsyncComponent(
					() =>
						import(
							'./heroHeaderSearchPlaceholderWrapper.vue'
						)
				)
			);
			app.component(
				'hero-header-facet-block',
				defineAsyncComponent(
					() =>
						import(
							'./heroHeaderFacetBlock.vue'
						)
				)
			);
			app.component(
				'video-block',
				defineAsyncComponent(
					() =>
						import('multisite/components/cmsContentBlocks/components/video.vue')
				)
			);
			app.component('general-link-block', GeneralLinkBlock);

			app.use(store);
			app.mount(htmlElement);
		});
	}
});

initSwiperMount();